import Rebase from "re-base";
import firebase from "firebase"

const firebaseApp = firebase.initializeApp({
	apiKey: "AIzaSyDfL6FEYEFQOjhPMSdOm_EKjO72FEg4QPo",
    authDomain: "catch-of-the-day-tim-osborn.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-tim-osborn.firebaseio.com",    
});

const base = Rebase.createClass(firebaseApp.database())

// this is a named export
export { firebaseApp };

//this is a default export
export default base;